import {Flipper, spring} from 'flip-toolkit'

/**
 * @property {HTMLElement} content
 * @property {HTMLFormElement} form
 */
export default class Filter {
    /**
     * 
     * @param {HTMLElement(null)} element 
     */
    constructor(element) {
        if (element === null) {
            return
        }

        this.content = element.querySelector('.js-filter-content')
        this.form = element.querySelector('.js-filter-form')
        this.bindEvents()
    }
    /**
     * Ajoute les comportements des éléments
     */
    bindEvents() {
        this.form.querySelectorAll('input').forEach(input => {
            input.addEventListener('change', this.loadForm.bind(this))
        })
    }
    async loadForm() {
        const data = new FormData(this.form)
        const url = new URL(this.form.getAttribute('action') || window.location.href)
        const params = new URLSearchParams()
        data.forEach((value, key) => {
            params.append(key, value)
        })
        return this.loadUrl(url.pathname + '?' + params.toString())
    }
    async loadUrl(url) {
        this.showLoader()
        const ajaxUrl = url + '&ajax=1'
        const response = await fetch(ajaxUrl, {
            headers: {
                'X-Requested-With': 'XMLHttpRequest'
            }
        })
        if (response.status >= 200 && response.status < 300) {
            const data = await response.json()
            this.flipContent(data.content)
        } else {
            console.error(response)
        }
        this.hideLodader()
    }
    /** 
     * Effet sur la grille
     * @param {string} content 
     * */
    flipContent(content) {
        const springConfig = 'woobly'
        const exitSpring = function (element, index, onComplete) {
            spring({
                config: springConfig,
                values: {
                    translateY: [0, -20],
                    opacity: [1, 0]
                },
                onUpdate: ({
                    translateY,
                    opacity
                }) => {
                    element.style.opacity = opacity;
                    element.style.transform = `translateY(${translateY}px)`;
                },
                onComplete
            })
        }
        const flipper = new Flipper({
            element: this.content
        })
        this.content.children.forEach(element => {
            flipper.addFlipped({
                element,
                spring: springConfig,
                flipId: element.id,
                shouldFlip: false,
                onExit: exitSpring
            })
        })
        flipper.recordBeforeUpdate()
        this.content.innerHTML = content
        this.content.children.forEach(element => {
            flipper.addFlipped({
                element,
                spring: springConfig,
                flipId: element.id
            })
        })
        flipper.update()
    }
    showLoader() {
        this.form.classList.add('is-loading')
        const loader = this.form.querySelector('.js-loading')
        if (loader === null) {
            return
        }
        loader.setAttribute('arian-hidden', 'false')
        loader.style.display = null
    }
    hideLodader() {
        this.form.classList.remove('is-loading')
        const loader = this.form.querySelector('.js-loading')
        if (loader === null) {
            return
        }
        loader.setAttribute('arian-hidden', 'true')
        loader.style.display = 'none'
    }
}