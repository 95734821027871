require('../css/app.scss');
// const $ = require('jquery');
import noUiSlider from 'nouislider';
import 'nouislider/distribute/nouislider.css';
import Filter from './modules/filter';
import lazyload from './modules/jquery.lazyload.min.js'
import {
	randomBytes
} from 'crypto';

import $script from 'scriptjs';

//----------HIDE NAVBAR ----------//
// var prevScrollpos = window.pageYOffset;
// window.onscroll = function() {
//   var currentScrollPos = window.pageYOffset;
//   if (prevScrollpos > currentScrollPos) {
//     document.getElementById("navbar").style.top = "0";
//   } else {
//     document.getElementById("navbar").style.top = "-200px";
//     $("div").removeClass("show")

//   }
//   prevScrollpos = currentScrollPos;
// }
//----------END HIDE NAVBAR ----------//
document.addEventListener('DOMContentLoaded', function() {
    var imagesToLoad = document.querySelectorAll('.img-fade-in');

    imagesToLoad.forEach(function(image) {
        if (image.complete) {
            // Afficher l'image si elle est déjà chargée (en cache)
            showImage(image);
        } else {
            // Sinon, ajouter un écouteur d'événement de chargement
            image.addEventListener('load', function() {
                showImage(image);
            });
        }
    });
});

function showImage(img) {
    img.style.visibility = 'visible';
    img.classList.add('loaded');
}

//----------LAZYLOAD ----------//
$(function() {
    $(".lazy").lazyload({
		threshold : 600,
		effect : "fadeIn"
    });
});
 
//----------END LAZYLOAD ----------//
const descriptions = document.getElementsByClassName("my-description");
const regex2 = /(logt dispo|libre le)[\s\S]*?(janvier|février|mars|avril|mai|juin|juillet|août|septembre|octobre|novembre|décembre|\d{1,2}[\/.]\d{1,2}[\/.]\d{2})/gi;
for (let i = 0; i < descriptions.length; i++) {
	const description = descriptions[i];
	const text = description.dataset.description;
	const result2 = text.match(regex2);
	if (result2 && result2.length > 0) {
		const divId = "liberation" + i; // Créer un identifiant unique pour chaque div
		// Changer la div avec l'ID correspondant
		const liberationDiv = document.getElementById(divId);
		if (liberationDiv) {
			liberationDiv.innerHTML = result2;
		}
	}
}
//----------BACKTOTOP ----------//
var btnBacktoTop = $('#backtotop');
var btnnewsletter = $('.sticky-btn');
$(window).scroll(function() {
  if ($(window).scrollTop() > 500) {
    btnBacktoTop.addClass('show');
	btnnewsletter.addClass('show');
  } else {
    btnBacktoTop.removeClass('show');
	btnnewsletter.removeClass('show');
  }
});
btnBacktoTop.on('click', function(e) {
  e.preventDefault();
  $('html, body').animate({scrollTop:0}, '300');
});
//----------END BACKTOTOP ----------//

/************ MAP */
let $map = document.querySelector('#map')
let activeMarker = null
class LeafletMap {
	constructor () {
		this.map = 	null
		this.bounds = []
	}
	async load(element) {
		return new Promise((resolve, reject) => {
			$script('https://unpkg.com/leaflet@1.6.0/dist/leaflet.js', () => {
				this.map = L.map(element, {scrollWheelZoom: true})
				L.tileLayer('https://api.mapbox.com/styles/v1/mapbox/streets-v11/tiles/{z}/{x}/{y}?access_token={accessToken}', {
					attribution: 'Map data &copy; <a href="https://www.openstreetmap.org/">OpenStreetMap</a> contributors, <a href="https://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>, Imagery © <a href="https://www.mapbox.com/">Mapbox</a>',
					accessToken: 'pk.eyJ1IjoicmFwaG1hbmlhIiwiYSI6ImNrYXZhNGRmMjEyeXoycmwyNWh1dWJvcGIifQ.1pY6kobmiCReCHGYukwgmA',
					id: 'mapbox/streets-v11',
					tileSize: 512,
					zoomOffset: -1,
				}).addTo(this.map)
				resolve()
			})
		})
	}
	addMarker(lat, lng, txt){
		let point = [lat, lng]
		this.bounds.push(point)
		return new LeafletMarker(point, txt, this.map)
	}
	center() {
		this.map.fitBounds(this.bounds)
	}
}
const initMap = async function() {
	let map = new LeafletMap()
	let hoverMarker = null
	await map.load($map)
	Array.from(document.querySelectorAll('.js-marker')).forEach((item) => {
		let marker = map.addMarker(item.dataset.lat, item.dataset.lng, item.dataset.ref)
		item.addEventListener('mouseover', function() {
			if(hoverMarker !== null) {
				hoverMarker.unsetActive()
			}
			marker.setActive()
			hoverMarker = marker
		})
		item.addEventListener('mouseleave',function() {
			if(hoverMarker !== null) {
				activeMarker.resetContent()
			}
		})
		marker.addEventListener('click', function() {
			if(activeMarker !== null) {
				console.log(activeMarker)
				activeMarker.resetContent()
			}
			marker.setContent(item.innerHTML)
			activeMarker = marker
		})
	})
	map.center()
}

class LeafletMarker {
	constructor(point, txt, map) {
		this.txt = txt
		this.popup = L.popup({
			autoClose: false,
			closeOnEscapeKey: false,
			closeOnClick: false,
			closeButton: false,
			className: 'marker',
			maxWidth: 300
		})
			.setLatLng(point)
			.setContent(txt)
			.openOn(map)
	}
	addEventListener(event, cb) {
		this.popup.addEventListener('add', () => {
			this.popup.getElement().addEventListener(event,cb)
		})
	}
	setContent(txt) {
		this.popup.setContent(
			txt +
			'<a id="close-button" style="position: absolute; top: -10px; right: 0;">Fermer</a>'
		);
		this.popup.getElement().classList.add('is-expanded');
		this.popup.getElement().classList.remove('is-opened');
		this.popup.update();
		document
			.getElementById('close-button')
			.addEventListener('click', () => activeMarker.resetContent())
			this.popup.update();
	}
	resetContent() {
		this.popup.setContent(this.txt);
		setTimeout(() => {
			this.popup.getElement().classList.remove('is-expanded');
			this.popup.getElement().classList.add('is-opened');
			this.popup.update();
			activeMarker = null;
		}, 100);
	}
}

if ($map !== null) {
	initMap()
}

/************* END MAP */
/***** fondu sur le carousel **/
// var images = document.querySelectorAll('.card-img-slider');
// images.forEach(function(image) {
//   var img = new Image();
//   img.onload = function() {
//     image.classList.add('fade-in');
//   }
//   img.src = image.style.backgroundImage.slice(4, -1).replace(/"/g, "");
// });


/***** fondu sur les images **/
// Sélectionnez l'élément qui utilise l'image de fond
var element = document.querySelector('.hidden-pics');
// Cachez l'élément jusqu'à ce que l'image de fond soit complètement chargée
element.style.opacity = 0;
// Créez une nouvelle image pour détecter lorsque l'image de fond est complètement chargée
var img = new Image();
img.src = getComputedStyle(element).getPropertyValue('background-image').replace(/^url\(["']?/, '').replace(/["']?\)$/, '');
// Détectez lorsque l'image est complètement chargée
img.addEventListener('load', function() {
    // Affichez progressivement l'élément une fois que l'image de fond est complètement chargée
    element.style.transition = 'opacity 1s';
    element.style.opacity = 1;
});


/************RELLAX JS */
// var rellaxIsActive = document.getElementsByClassName('rellax');
// if (rellaxIsActive.length > 0) {
// 	var rellax = new Rellax('.rellax', {center: true});
// }
/***********END RELLAX JS */

// new Filter(document.querySelector('.js-filter'))

/**
 **** FILTER JS  ****
 */
// const slider = document.getElementById('price-slider');
// if (slider) {
// 	const budgetMin = document.getElementById('budgetMin')
// 	const budgetMax = document.getElementById('budgetMax')
// 	const range = noUiSlider.create(slider, {
// 		start: [budgetMin.value || 0, budgetMax.value || 1500],
// 		connect: true,
// 		step: 50,
// 		range: {
// 			'min': 0,
// 			'max': 1500
// 		}
// 	})
// 	range.on('slide', function (values, handle) {
// 		if (handle === 0) {
// 			budgetMin.value = Math.round(values[0])
// 		}
// 		if (handle === 1) {
// 			budgetMax.value = Math.round(values[1])
// 		}
// 	})
// 	range.on('end', function (values, handle) {
// 		budgetMin.dispatchEvent(new Event('change'))
// 	})
// }

// const roomSlider = document.getElementById('room-slider');
// if (roomSlider) {
// 	const roomMin = document.getElementById('roomMin')
// 	const roomMax = document.getElementById('roomMax')
// 	const range = noUiSlider.create(roomSlider, {
// 		start: [roomMin.value || 0, roomMax.value || 20],
// 		connect: true,
// 		step: 1,
// 		range: {
// 			'min': 0,
// 			'max': 10
// 		}
// 	})
// 	range.on('slide', function (values, handle) {
// 		if (handle === 0) {
// 			roomMin.value = Math.round(values[0])
// 		}
// 		if (handle === 1) {
// 			roomMax.value = Math.round(values[1])
// 		}
// 	})
// 	range.on('end', function (values, handle) {
// 		roomMin.dispatchEvent(new Event('change'))
// 	})
// }

// const surfaceSlider = document.getElementById('surface-slider');
// if (surfaceSlider) {
// 	const surfaceMin = document.getElementById('surfaceMin')
// 	const surfaceMax = document.getElementById('surfaceMax')
// 	const range = noUiSlider.create(surfaceSlider, {
// 		start: [surfaceMin.value || 0, surfaceMax.value || 200],
// 		connect: true,
// 		step: 15,
// 		range: {
// 			'min': 0,
// 			'max': 200
// 		}
// 	})
// 	range.on('slide', function (values, handle) {
// 		if (handle === 0) {
// 			surfaceMin.value = Math.round(values[0])
// 		}
// 		if (handle === 1) {
// 			surfaceMax.value = Math.round(values[1])
// 		}
// 	});
// 	range.on('end', function (values, handle) {
// 		surfaceMin.dispatchEvent(new Event('change'))
// 	})
// }

// var input = document.getElementById("city");
// input.addEventListener("keyup", function(event) {
//     if (event.keyCode === 13) {
//         event.preventDefault();
//         document.getElementById("myBtn").click();
//     }
// })

/******************************END FILTER JS */

